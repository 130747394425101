import React, { useEffect } from "react";
import { NavLink, Link } from "react-router-dom";
import Certificate from '../Assest/Certificate.jpg'
const Footer = () => {
  return (
    <footer
      className="footer"
      style={{ backgroundImage: "url(/assets/images/background/footer.jpg)" }}
    >
      {/* Footer Top */}
      <div className="footer-top border border-top-primary">
        <div className="container">
          <div className="row">
            <div className="col-xl-3 col-lg-3 col-6">
              <div className="widget footer_widget ml-50">
                <h3 className="footer-title">Our Range</h3>
                <ul className="footer__links_7">
                  <li>
                    <a href="/products-single/Tablets">
                      <span>ANTIBIOTIC TABLETS</span>
                    </a>
                  </li>
                  <li>
                    <a href="/products-single/Syrup">
                      <span>ANTIBIOTIC SYRUP</span>
                    </a>
                  </li>
                  <li>
                    <a href="/products-single/Tablets">
                      <span>HAEMATINIC TABLETS</span>
                    </a>
                  </li>
                  <li>
                    <a href="/products-single/Gynaecare">
                      <span>GYNAECARE</span>
                    </a>
                  </li>
                  <li>
                    <a href="/products-single/Injection">
                      <span>INJECTABLES</span>
                    </a>
                  </li>
                  <li>
                    <a href="/products">
                      <span>NEURO PRODUCTS</span>
                    </a>
                  </li>
                  <li>
                    <a href="/products">
                      <span>NUTRACEUTICALS</span>
                    </a>
                  </li>
                  <li>
                    <a href="/products-single/Syrup">
                      <span>SYRUPS &amp; SUSPENSION</span>
                    </a>
                  </li>
                  <li>
                    <a href="/products-single/Tablets">
                      <span>ANTISPASMODIC TABLETS</span>
                    </a>
                  </li>
                  <li>
                    <a href="/products-single/Tablets">
                      <span>ANALGESICS &amp; MUSCLE RELAXANT</span>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-xl-3 col-lg-3 col-6">
              <div className="widget footer_widget">
                <h3 className="footer-title">Our Range</h3>
                <ul className="footer__links_7">
                  <li>
                    <a href="/products">
                      <span>PPI’S</span>
                    </a>
                  </li>
                  <li>
                    <a href="/products">
                      <span>CARDIAC &amp; DIABETIC</span>
                    </a>
                  </li>
                  <li>
                    <a href="/products">
                      <span>DENTAL</span>
                    </a>
                  </li>
                  <li>
                    <a href="/products">
                      <span>DERMA</span>
                    </a>
                  </li>
                  <li>
                    <a href="/products-single/Ear-Eye-Drops">
                      <span>ENT CARE</span>
                    </a>
                  </li>
                  <li>
                    <a href="/products-single/Ear-Eye-Drops">
                      <span>EYE DROPS</span>
                    </a>
                  </li>
                  <li>
                    <a href="/products-single/Gynaecare">
                      <span>GYNAECARE</span>
                    </a>
                  </li>
                  <li>
                    <a href="/products-single/Tablets">
                      <span>HAEMATINIC TABLETS</span>
                    </a>
                  </li>
                  <li>
                    <a href="/products">
                      <span>HEMOSTATICS</span>
                    </a>
                  </li>
                  <li>
                    <a href="/products">
                      <span>HERBAL PRODUCT</span>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-xl-3 col-lg-3 col-6">
              <div className="widget footer_widget ml-50">
                <h3 className="footer-title">Quick Links</h3>
                <ul className="footer__links_7">
                  <li>
                    <Link to={"/"}>
                      <span>HOME</span>
                    </Link>
                  </li>

                  <li>
                    <Link to="/about-us">
                      <span>ABOUT US</span>
                    </Link>
                  </li>
                  <li>
                    <Link to={"/products"} href="#">
                      <span> OUR PRODUCTS</span>
                    </Link>
                  </li>
                  <li>
                    <Link to={"blogs"}>
                      <span>BLOGS</span>
                    </Link>
                  </li>

                  <li>
                    <Link to="/contact-us">
                      <span>CONTACT US</span>
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-xl-3 col-lg-3 col-md-6">
              <div className="widget widget_info">
                <div className="footer-logo">
                  <a href="#">
                    <img src="/assets/images/Bioraiz_Logo_final.png" alt="" />
                  </a>
                </div>
                <div className="ft-contact">
                  <p>
                  Bioraiz Healthcare is a leading ISO 9001:2015 certified <a href="https://www.bioraiz.in/pcd-pharma-franchise-company">PCD Pharma Franchise Company </a> in Ambala.
                  </p>
                  <div className="contact-bx">
                    <div className="icon">
                      <i className="fas fa-phone-alt" />
                    </div>
                    <div className="contact-number">
                      <span>Contact Us</span>
                      <h4 className="number">+91 7289822446 </h4>
                    </div>
                  </div>
                  <ul className="imp__icons_fftr mt-2">
                    <li>
                      {" "}
                      <a
                        href={Certificate}
                        target="_blank"
                       
                      >
                        <img
                          className="imp__log1"
                          src={Certificate}
                          style={{ width: "100px", height: "60px" }}
                          alt="Certificate"
                        />
                      </a>{" "}
                    </li>

                    <li>
                      {" "}
                      <img
                        className="imp__log1"
                        src="/assets/images/imp_log2.webp"
                      />
                    </li>

                    <li>
                      {" "}
                      <img
                        className="imp__log1"
                        src="/assets/images/imp_log3.webp"
                      />{" "}
                    </li>
                    <li>
                      {" "}
                      <img
                        className="imp__log1"
                        src="/assets/images/imp_log_.webp"
                      />{" "}
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* footer bottom */}
      <div className="container">
        <div className="footer-bottom">
          <div className="row">
            <div className="col-12 text-center">
              <p className="copyright-text">
                Copyright © 2023{" "}
                <a href="#" target="_blank" className="text-secondary" />
              </p>
            </div>
          </div>
        </div>
      </div>
      {/* footer-shape */}

      <img
        className="pt-img1 animate-wave"
        src="/assets/images/wave-blue.png"
        alt=""
      />
      <img
        className="pt-img2 animate1"
        src="/assets/images/circle-dots.png"
        alt=""
      />
      <img
        className="pt-img3 animate-rotate"
        src="/assets/images/plus-blue.png"
        alt=""
      />

      <img
        className="pt-img4 animate-wave"
        src="/assets/images/wave-blue.png"
        alt=""
      />
    </footer>
  );
};

export default Footer;
