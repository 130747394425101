import React from 'react'
import { Link } from "react-router-dom";

const FixButtons = ({openModal}) => {
  return (
    <>
            <Link
        className="fx__textbtn anchor-hover-rj"
        to="/assets/images/product-listr.pdf"
        target="_blank"
        download
      >
        Download Price List
      </Link>
      <button
        className="fx__textbtn anchor-hover-rj enquire-fix"
        to=""
        target=""
        onClick={openModal}
      >
        Enquire Us
      </button>

      <div className="social__btns_7">
        <a href="https://www.facebook.com/profile.php?id=61565489412730" className="pd1">
          <i className="fa-brands fa-facebook-f"></i>
        </a>
        {/* <a href="#">
          <i className="fa-brands fa-google-plus-g"></i>
        </a> */}
        <a href="https://x.com/Bioraiz13">
          <i className="fa-brands fa-x-twitter"></i>
        </a>
        {/* <a href="#">
          <i className="fa-brands fa-youtube"></i>
        </a> */}
        <a href="https://www.linkedin.com/company/bioraiz-healthcare-pvt-ltd/">
          <i className="fa-brands fa-linkedin"></i>
        </a>
        <a href="https://www.instagram.com/bioraiz_healthcare/">
          <i className="fa-brands fa-instagram"></i>
        </a>
        {/* <a href="#">
          {" "}
          <i className="fa-brands fa-whatsapp"></i>
        </a> */}
      </div> 
      
    </>
  )
}

export default FixButtons
