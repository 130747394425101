import React, { useState } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  RouterProvider,
  createBrowserRouter,
} from "react-router-dom";
import Header from "./components/Header";
import Home from "./pages/Home";
import AboutPage from "./pages/About";
import Products from "./pages/Products";
import BlogPage from "./pages/Blogs";
import PharmaFranchise from "./pages/Pharma-franchise";
import PharmaFranchiseCompany from "./pages/Pharma-FranchiseCompany";
import IndianPcdPharamaFranchise from "./pages/IndianPcdPharamaFranchise";
import PcdPharmaFranchise from "./pages/PcdPharamFranchise";
import PcdPharmaFranchiseCompany from "./pages/PcdPharamaFranchiseCompany";
import VisualAid from "./pages/Visual";
import Footer from "./components/Footer";
import Contact from "./pages/Contact";
import ModalContactForm from "./components/ModalContactForm";
import { Modal, Button, Placeholder } from "rsuite";
import "rsuite/dist/rsuite.min.css";
import Root from "./Root";
import HomePage from "./pages/Home";
import VideoSlider from "./components/VideoSlider";
import SingleProduct from "./pages/SingleProduct";
import BlogDeatils from "./pages/BlogDeatils";
import ProductListing from "./pages/ProductListing";
import PromotionInput from "./pages/PromotionInput";

function App() {
  const [open, setOpen] = useState(true);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const router = createBrowserRouter([
    {
      path: "/",
      element: <Root />,
      children: [
        {
          path: "/",
          element: <Home />,
        },
        {
          path: "/video",
          element: <VideoSlider />,
        },
        {
          path: "/about-us",
          element: <AboutPage />,
        },
        {
          path: "/products",
          element: <Products />,
        },
        {
          path: "/products-single/:id",
          element: <SingleProduct />,
        },
        {
          path: "/modal-form",
          element: <ModalContactForm />,
        },
        {
          path: "/blogs",
          element: <BlogPage />,
        },
        {
          path: "/:blogView/",
          element: <BlogDeatils />,
        },
        {
          path: "/pharma-franchise",
          element: <PharmaFranchise />,
        },
        {
          path: "/pharma-franchise-companies",
          element: <PharmaFranchiseCompany />,
        },
        {
          path: "/pcd-pharma-companies",
          element: <PcdPharmaFranchise />,
        },
        {
          path: "/pcd-pharma-franchise-company",
          element: <PcdPharmaFranchiseCompany />,
        },
        {
          path: "/indian-pcd-pharma-companies",
          element: <IndianPcdPharamaFranchise />,
        },
        {
          path: "/VisualAid",
          element: <VisualAid />,
        },
        {
          path: "/contact-us",
          element: <Contact />,
        },

        {
          path: "/Product-Listing",
          element: <ProductListing />,
        },
        {
          path: "/Promotion-Input",
          element: <PromotionInput />,
        },
      ],
    },
  ]);
  return <RouterProvider router={router} />;
  
}

export default App;
