import React, { useState, useEffect } from "react";
import { useParams, Link } from "react-router-dom";
import axios from "axios";
import { parseISO, format } from "date-fns";
import { LazyLoadImage } from "react-lazy-load-image-component";
import he from "he";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import LoderView from "../components/LoderView";
import { FaUserCircle } from "react-icons/fa";
// import { faL } from "@fortawesome/free-solid-svg-icons";
import dummy_img from "../Assest/dummy-img-1.jpg";
import CommentForm from "../components/CommentForm";
import { BsArrowReturnLeft, BsX } from "react-icons/bs";
import Seo from "../components/Seo";
function BlogDeatils() {
  const [data, setData] = useState("");
  const [loading, setLoading] = useState(false);
  const [isDate, setIsDate] = useState();
  const [blogData, setBlogData] = useState([]);
  const [rating, setRating] = useState(0);
  const [hoveredRating, setHoveredRating] = useState(0);
  const [allComment, setAllComment] = useState([]);
  const [showReplyForm, setShowReplyForm] = useState(false);
  const [showAllReplies, setShowAllReplies] = useState(false);
  const [commentForm, setCommentForm] = useState({
    name: "",
    email: "",
    comment: "",
    url: "",
  });
  const blogId = useParams();
  const id = blogId.blogView;
 // console.log(id, "jjjuttryjfgjhtr");

  const toggleReplyForm = (id) => {
    setShowReplyForm((prevShowReplyForm) => {
      return prevShowReplyForm === id ? null : id;
    });
  };
  const handleStarHover = (index) => {
    setHoveredRating(index);
  };

  const handleStarClick = (index) => {
    setRating(index);
  };
  const handleMouseLeave = () => {
    setHoveredRating(0);
  };
  const handleChange = (e) => {
    const { name, value } = e.target;
    setCommentForm({ ...commentForm, [name]: value });
  };
  const renderStar = (starIndex) => {
    const filled = starIndex <= (hoveredRating || rating);
    const color = filled ? "yellow" : "grey";
    return (
      <span
        key={starIndex}
        style={{ color, cursor: "pointer" }}
        onMouseEnter={() => handleStarHover(starIndex)}
        onClick={() => handleStarClick(starIndex)}
        onMouseLeave={handleMouseLeave}
      >
        ★
      </span>
    );
  };

  const starsCount = 5;
  const stars = Array.from({ length: starsCount }, (_, index) => index + 1);

  const getApiBlog = async () => {
    try {
      const result = await axios.get(
        `https://bioraiz.in/bioraiz_admin/api/get_blog/${id}`
      );
      const res = await axios.get(
        "https://bioraiz.in/bioraiz_admin/api/get_blog"
      );

      const transformedData = result.data.reduce((acc, item) => {
        return item;
      }, {});
      setData(transformedData);
      const filter = res.data.filter((itm) => {
        return transformedData.id !== itm.id ? itm : null;
      });
      setBlogData(filter);
      setLoading(false);
      const dateObject = parseISO(transformedData.created_at);
      const formattedDate = format(dateObject, "MMM dd, yyyy");
      setIsDate(formattedDate);
    } catch (error) {
      setLoading(true);
    }
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    if (
      !commentForm.comment ||
      !commentForm.name ||
      !commentForm.email ||
      !rating
    ) {
      // alert("Please fill in all required fields and rating also");
      return toast.error("Please fill all  the  fields!");
    }

    try {
      const formData = new FormData();
      formData.append("blog_id", data.id);
      formData.append("name", commentForm.name);
      formData.append("email", commentForm.email);
      formData.append("website", commentForm.url);
      formData.append("comment", commentForm.comment);
      formData.append("rating", rating);
      const res = await axios.post(
        `https://bioraiz.in/bioraiz_admin/api/add_comment/${data.id}`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      if (res.status == 200) {
        toast.success("Comment has been created successfully", 2000);
        setCommentForm({
          name: "",
          email: "",
          comment: "",
          url: "",
        });
        setRating(0);
        fetchComments();
      } else {
        toast.error("Error adding comment");
      }

      // const response = await promise;
      // console.log(response?.data?.msg);

      // alert(response?.data?.msg);
      // get api of comments....
      // const comment_res = await axios.get(
      //   `https://staunchtraders.in/new-admin/api/get_comment/${data.id}`
      // );
      // setAllComment(comment_res.data);
    } catch (error) {
      console.error("Error adding comment:", error);
    }
  };
  const formatDate = (dateString) => {
    const options = {
      month: "long",
      day: "numeric",
      year: "numeric",
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    };
    const date = new Date(dateString);
    return date.toLocaleDateString("en-US", options);
  };
  // console.log(allComment, "gurii");
 // console.log(data, "jiyrtgdf");

  useEffect(() => {
    setLoading(true);
    setShowReplyForm(false);
    setShowAllReplies(false);
    getApiBlog();
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, [blogId, data.id]);
  const fetchComments = async () => {
    try {
      const commentRes = await axios.get(
        `https://bioraiz.in/bioraiz_admin/api/get_comment/${data?.id}`
      );
      setAllComment(commentRes.data);
      setShowReplyForm(false);
    //  console.log("comments data", commentRes.data);
    } catch (error) {
      console.error("Error fetching comments:", error);
    }
  };
  useEffect(() => {
    fetchComments();
  }, [data.id]);
  // console.log(allComment,data.id, "jjjjj");
  if (loading) {
    return <LoderView />;
  }

  // console.log("Meta Description:", data.meta_description);

  return (
    <div className="container my-5">
       <Seo
        title={`Bioraiz Healthcare | ${data.meta_title}`}
        description={`${data.meta_description}`}
        type="webapp"
        name="Bioraiz Pharma"
        focusKeyphrase={`${data.focus_keyphrase}`}
      />
      <div className="row mx-auto">
        <div className="col-lg-8 col-md-8 col-sm-12 mt-md-5 ">
          <div className="row">
            <div className="col-12 mx-auto blog_img_border py-5">
              <div className="d-flex justify-content-center">
                <LazyLoadImage
                  src={data?.image}
                  className="img-fluid"
                  alt="..."
                />
              </div>
              <div className="col-12">
                <div className="d-flex justify-content-between align-items-center my-3 px-5">
                  {/* {/ <div className="fw-bolder">Categories {data?.category}</div> /} */}
                  <div>
                    <h1
                      className="header-align fw-bolder"
                      style={{ fontSize: "26px" }}
                    >
                      {data?.title}
                    </h1>
                  </div>
                  <div className="fw-bolder st-post-date">
                    <h6>{isDate}</h6>
                  </div>
                </div>

                <p className="blog_desc_1 px-5 mt-md-4">
                  {/* {/ Check if data.description is available before rendering /} */}
                  {data?.description ? (
                    <span
                      dangerouslySetInnerHTML={{
                        __html: he.decode(data.description),
                      }}
                    />
                  ) : (
                    "No description available"
                  )}
                </p>
                {/* <p className="pb-4">
                  {data?.description_front}</p> */}
              </div>
            </div>
            <div className="row my-md-3">
              <div className="col-12">
                <h5 class="comments-title">
                  {allComment.length} comments of {data.title}
                </h5>
                <ol className="comment-list">
                  {allComment.map((comment, index) => (
                    <li
                      key={index}
                      id={`comment-${index}`}
                      className={`comment ${
                        index % 2 === 0 ? "even" : "odd"
                      } thread-${index % 2 === 0 ? "even" : "odd"} depth-1`}
                    >
                      <article
                        id={`div-comment-${index}`}
                        className="comment-body"
                      >
                        <footer className="comment-meta">
                          <div className=" vcard p-2">
                            <LazyLoadImage
                              src={dummy_img}
                              alt="Dummy Image"
                              className="dummy-img"
                            />
                            <b className="fn">
                              <Link
                                href={comment.website}
                                className="url text-white ms-2"
                                rel="ugc external nofollow"
                              >
                                {comment.name}
                              </Link>
                            </b>{" "}
                            <span className="says text-lowercase">says:</span>
                          </div>

                          <div className="comment-metadata p-1">
                            <p className="comment-date">
                              {formatDate(comment.created_at)}
                            </p>
                          </div>
                        </footer>
                        <div className="comment-content">
                          <p className="m-md-1">Review</p>
                          <div className="saswp-rvw-str">
                            <span className="saswp_star_color">
                              {Array.from(
                                { length: comment.rating },
                                (_, i) => (
                                  <svg
                                    key={i}
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 24 24"
                                    width="20"
                                    height="20"
                                  >
                                    <path fill="none" d="M0 0h24v24H0z" />
                                    <path d="M12 17.27L18.18 21l-1.64-7.03L22 9.24l-7.19-.61L12 2 9.19 8.63 2 9.24l5.46 4.73L5.82 21z" />
                                    <path d="M0 0h24v24H0z" fill="none" />
                                  </svg>
                                )
                              )}
                            </span>
                          </div>
                          <p></p>
                          <p>{comment.comment}</p>
                        </div>
                        <div className="reply_comment my-2" key={index}>
                          {comment.replies.length > 0 && (
                            <>
                              {/* <ol>
                                {comment.replies
                                  .slice(0, 1)
                                  .map((reply, replyIndex) => (
                                    <li key={replyIndex}>
                                      <p className="fw-bold">
                                        Replies :{" "}
                                        <span className="mx-1">
                                          {reply.name}
                                        </span>
                                      </p>
                                      <p>{reply.comment}</p>
                                    </li>
                                  ))}
                              </ol> */}
                              {comment.replies.length > 0 && (
                                <button
                                  className=" p-2  btn btn-primary rounded"
                                  onClick={() =>
                                    setShowAllReplies(!showAllReplies)
                                  }
                                >
                                  {showAllReplies
                                    ? "Hide replies"
                                    : "Show all replies"}
                                </button>
                              )}
                              {showAllReplies && (
                                <ol className="p-0 m-0">
                                  {comment.replies
                                    .slice(1)
                                    .map((reply, replyIndex) => (
                                      <li
                                        className="border my-2 p-2"
                                        key={replyIndex}
                                      >
                                        <div className="replyWrapper d-flex mb-2">
                                          <div className="d-flex align-items-center flex-1">
                                            <FaUserCircle size={20} />
                                          </div>
                                          <div className="flex-1 mx-2">
                                            <p className="highlightedName">
                                              {reply.name}
                                            </p>
                                          </div>
                                        </div>

                                        <p className="">{reply.comment}</p>
                                      </li>
                                    ))}
                                </ol>
                              )}
                            </>
                          )}
                        </div>
                        <div className="reply my-2">
                          <div className="reply">
                            <button
                              onClick={() => {
                                toggleReplyForm(comment.id);
                              }}
                              className="btn btn-link"
                            >
                              {showReplyForm === comment.id ? (
                                <>
                                  Cancel <BsX />
                                </>
                              ) : (
                                <>
                                  <BsArrowReturnLeft /> Reply
                                </>
                              )}
                            </button>
                          </div>

                          {showReplyForm === comment.id && (
                            <CommentForm
                              comment_Id={comment.id}
                              blog_Id={data?.id}
                              fetchComments={fetchComments}
                            />
                          )}
                        </div>
                      </article>
                    </li>
                  ))}
                </ol>

                <div className="comment_respond">
                  <h3>Leave a Reply </h3>
                  <h2>
                    {stars.map(renderStar)}
                    {hoveredRating !== 0 && (
                      <span>Rating: {hoveredRating}</span>
                    )}
                  </h2>

                  <p className="comment-notes">
                    <span id="email-notes">
                      Your email address will not be published.
                    </span>
                    <span className="required-field-message">
                      Required fields are marked{" "}
                      <span className="required">*</span>
                    </span>
                  </p>
                  <form onSubmit={onSubmit}>
                    <div className="mb-3">
                      <label htmlFor="comment" className="form-label">
                        Comment <span className="required">*</span>
                      </label>
                      <textarea
                        className="form-control"
                        id="comment"
                        value={commentForm.comment}
                        onChange={handleChange}
                        name="comment"
                        rows="8"
                        maxLength="65525"
                        required
                      ></textarea>
                    </div>
                    <div className="mb-3">
                      <label htmlFor="author" className="form-label">
                        Name <span className="required">*</span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="author"
                        name="name"
                        value={commentForm.name}
                        onChange={handleChange}
                        maxLength="245"
                        autoComplete="name"
                        required
                      />
                    </div>
                    <div className="mb-3">
                      <label htmlFor="email" className="form-label">
                        Email <span className="required">*</span>
                      </label>
                      <input
                        type="email"
                        className="form-control"
                        id="email"
                        name="email"
                        value={commentForm.email}
                        onChange={handleChange}
                        maxLength="100"
                        aria-describedby="email-notes"
                        autoComplete="email"
                        required
                      />
                    </div>
                    <div className="mb-3">
                      <label htmlFor="url" className="form-label">
                        Website
                      </label>
                      <input
                        type="url"
                        className="form-control"
                        id="url"
                        name="url"
                        value={commentForm.url}
                        onChange={handleChange}
                        maxLength="200"
                        autoComplete="url"
                      />
                    </div>
                    <button type="submit" className="btn btn-primary">
                      Submit
                    </button>
                  </form>

                  <ToastContainer position="top-center" />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="col-lg-4 col-md-4 col-sm-12  ">
          <div className="recent_post">
            <h3>Recent Posts</h3>
            {blogData.slice(0, 5).map((item, index) => {
              const dateObject = parseISO(item.created_at);
              const formattedDate = format(dateObject, "MMM dd, yyyy");
              return (
                <Link
                  to={`/${item.slug}/`}
                  className="blog_container my-3"
                  key={index}
                >
                  <div className="image_container_left">
                    <LazyLoadImage src={item.image} width={150} height={150} />
                  </div>

                  <div className="text_container_right">
                    <Link to={`/${item.slug}/`} style={{ color: "#03175b" }}>
                      <h4 className="fw-normal text-capitalize">
                        {item.title.slice(0, 20) + "..."}
                      </h4>
                    </Link>
                    <h4 className="blog_date_top st-post-date">
                      {formattedDate}
                    </h4>
                    {/* <div
                      dangerouslySetInnerHTML={{
                        __html: item?.description_front.slice(0, 10) + "...",
                      }}
                    /> */}
                    {/* <h5 className="blog_desc_55">
                        {item.description_front.slice(0, 70)}...
                      </h5> */}
                  </div>
                </Link>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
}

export default BlogDeatils;
